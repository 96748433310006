<template>
  <div>
    <!-- <el-select @clear="clear" clearable v-model="marketPlaceId" style="margin-bottom:15px;width：150px;margin-right:10px" placeholder="请选择店铺">
        <el-option-group
        v-for="group in tress"
        :key="group.id"
        :label="group.name">
          <el-option
              v-for="item in group.marketPlaceTree"
              :key="item.id"
              :label="item.name"
              :value="item.id">
              <p @click="getID(group.id,item.id)">{{item.name}}</p>
          </el-option>
        </el-option-group>
    </el-select> -->
    <ShopsAndSites
        v-if="tress.length==0?false:true"
        placeholder="请选择店铺(整页生效)"
        :clearable='false'
        style="margin-right:10px;"
        :list='tress'
        @getShopId='getID'>
    </ShopsAndSites>
    <div style="margin-top:10px" v-loading='topLoading' element-loading-background="rgba(255, 255, 255, 0.5)" element-loading-text="拼命加载中" class="TOP">
      <div class="first" style="background:#3894FF">
        <div>
          <div>
            <div></div>
            <div>销售额
              <el-tooltip effect="dark" placement="top">
                <div slot="content" style="line-height:25px;width:400px">
                  <span>所指的是：<span style="color: #CD853F">商品的销售额（欧洲市场shipped状态订单已扣VAT），而非订单金额</span>,</span><br/>
                  <span>即不包含各项税费, 运费、礼品费和扣除促销等相关费用的金额。</span><br/>
                  <span>计算逻辑：销售订单<span style="color: #CD853F">（不包括：取消、预售未付款授权 状态的订单）</span>中的商品单价 * 销售数量的金额总和。</span><br/>
                  <span>某一笔订单的销售额（商品销售额），就是这笔订单在亚马逊后台交易详情中的商品价格。</span><br/>
                  <span>① 销售订单的归属日期, 以下单时间进行统计;</span><br/>
                  <span>② 如果订单发生退款, 此订单会正常统计销售额, 对应的退款金额会以退款时间, 统计到当日总支出的退款项中;</span><br/>
                  <span>③ 部分pending订单, 亚马逊一段时间内, 返回的销售数据可能为0, </span><br/>
                  <span>此时系统会按最近成交的情况进行预算<span style="color: #CD853F">（预算数据项包括：销售额、亚马逊佣金、FBA费用）</span>;</span>
                </div>
               <span class="el-icon-question"></span>
              </el-tooltip>
            </div>
            <div>广告销售额
              <el-tooltip effect="dark" placement="top">
                <div slot="content" style="line-height:25px;width:400px">
                  <span>通过广告带来的销售额。</span><br/>
                  <span>1、因近30天（尤其近3天）亚马逊接口返回的数据, 可能与亚马逊控制台展现的数据存在略微不一致,</span><br/>
                  <span>因此可能导致系统统计结果与亚马逊控制台展现的数据存在略微不一致;</span><br/>
                  <span>2、以商品和站点不同维度查看数据, 因数据来自亚马逊不同的接口,</span><br/>
                  <span>不同接口的请求时间或者报告生成时间可能不一致, 因此可能导致两个维度下统计结果存在略微不一致;</span>
                </div>
                <span class="el-icon-question"></span>
              </el-tooltip>
            </div>
            <div>AsoTs
              <el-tooltip effect="dark" placement="top" >
                <div slot="content" style="line-height:25px">
                  <span>通过广告所带来的销售额占总销售额的比例。</span><br/>
                  <span>计算规则：ASoTS = 广告销售额 / 销售额 * 100%;</span>
                </div>
                <span class="el-icon-question"></span>
              </el-tooltip>
            </div>
          </div>
          <div>
            <div>{{today.dateStr}}</div>
            <div>￥{{today.salesAmount}}</div>
            <div>￥{{today.adSalesAmount}}</div>
            <div>{{today.ASoTS}}%</div>
          </div>
        </div>
        <div>
          <div>
            <div>{{yesterdayRes.dateStr}}</div>
            <div>￥{{yesterdayRes.salesAmount}}</div>
            <div>￥{{yesterdayRes.adSalesAmount}}</div>
            <div>{{yesterdayRes.ASoTS}}%</div>
          </div>
          <div>
            <div>{{lastWeekRes.dateStr}}</div>
            <div>￥{{lastWeekRes.salesAmount}}</div>
            <div>￥{{lastWeekRes.adSalesAmount}}</div>
            <div>{{lastWeekRes.ASoTS}}%</div>
          </div>
        </div>
      </div>
      <div class="second" style="background:#68BBC4">
        <div>
          <div>
            <div>总支出
              <el-tooltip effect="dark" placement="bottom">
                <div slot="content" style="line-height:25px;width:400px">
                  <!-- 未完待续.... -->
                  <span>所有支出的总和, 支出项包括：广告花费、FBA费用、亚马逊佣金、促销费用、退款金额 + 退款费用、</span><br/>
                  <span>亚马逊其它费用、系统设置商品成本 、系统设置其它成本 。</span><br/>
                  <span><span style="color: #CD853F">特别说明</span>：1、如果亚马逊平台代收了增值税, 则亚马逊其它费用中会包含增值税, 即总支出中包括增值税; </span><br/>
                  <span>2、coupon类促销订单, 系统的促销费用中会包括亚马逊收取的费用, 即总支出中包括coupon的使用费用,</span><br/>
                  <span>因此系统订单中展示的促销费用可能大于亚马逊交易一览中的促销金额; </span><br/>
                  <span>1、如果订单发生退款, 此订单会正常统计销售额, 对应的退款金额会以退款时间, 统计到当日总支出的退款项中;</span><br/>
                  <span>2、部分pending订单, 亚马逊短时间内, 返回的销售数据可能为0, </span><br/>
                  <span>此时系统会按最近成交的情况进行预算<span style="color: #CD853F">（预算数据项包括：销售额、亚马逊佣金、FBA费用）</span>;</span><br/>
                  <span>3、亚马逊其它费用：亚马逊订单明细中, 除了 商品价格、FBA费用、佣金、促销费用 <span style="color: #CD853F">之外的其它费用总和</span>,</span><br/>
                  <span>此值出现负数的原因是：a、有些促销活动是亚马逊平台搞的, 但是这部分费用会被一并统计到总支出的促销费用中,</span><br/>
                  <span>因此需要进行对冲。b、增值税亚马逊未代收, 此时增值税需要加入到收入中, 对于费用来说是负数;</span><br/>
                  <span>c、FBM订单，运费需要加入到收入中，对于费用来说是负数;</span><br/>
                  <span>4、系统设置其它成本：店铺实际运营过程中,产生的一些特殊费用, 可以是收入, 也可以是支出, </span><br/>
                  <span>用于准确计算利润, 可在“库存管理 - 其它成本”中进行上传;</span><br/>
                  <span>5、系统设置商品成本：包括商品的采购成本、物流成本（头程 / FBM）、其它费用，可在“库存管理 - 商品成本”中进行设置或者上传;</span>
                </div>
                <span class="el-icon-question"></span>
              </el-tooltip>
            </div>
            <div>广告花费
              <el-tooltip effect="dark" placement="top" >
                <div slot="content" style="line-height:25px;width:400px">
                  <span>广告被点击, 所产生的总费用, 相当于您的<span style="color: #CD853F">广告总成本。</span></span><br/>
                  <span>1、因近30天（尤其近3天）亚马逊接口返回的数据, 可能与亚马逊控制台展现的数据存在略微不一致,</span><br/>
                  <span>因此可能导致系统统计结果与亚马逊控制台展现的数据存在略微不一致; </span><br/>
                  <span>2、以商品和站点不同维度查看数据，因数据来自亚马逊不同的接口, </span><br/>
                  <span>不同接口的请求时间或者报告生成时间可能不一致, 因此可能导致两个维度下统计结果存在略微不一致; </span><br/>
                  <span>3、计算规则：广告花费 = CPC竞价 * 被点击次数;</span>
                </div>
                <span class="el-icon-question"></span>
              </el-tooltip>
            </div>
            <div>毛利润
              <el-tooltip effect="dark" placement="top" content="计算规则: 毛利润 = 销售额 - 总支出。">
                <span class="el-icon-question"></span>
              </el-tooltip>
            </div>
            <div>毛利率
              <el-tooltip effect="dark" placement="top" content="计算规则: 毛利率 = 毛利率/销售额*100%">
                <span class="el-icon-question"></span>
              </el-tooltip>
            </div>
          </div>
          <div>
            <div>
              <el-popover
                placement="bottom"
                width="310"
                trigger="hover">
                <p class="outTitle">当期总支出明细</p>
                <div class="outList">
                  <ul>
                    <li>广告花费:</li>
                    <li>FBA费用:</li>
                    <li>亚马逊佣金:</li>
                    <li>促销费用:</li>
                    <li>退款金额+退款费用:</li>
                    <li>亚马逊其他费用:</li>
                    <li>系统设置商品成本:</li>
                    <li>系统设置其他成本:</li>
                  </ul>
                  <ul>
                    <li>{{today.advertFee}}</li>
                    <li>{{today.fbaFee}}</li>
                    <li>{{today.referralFee}}</li>
                    <li>{{today.promotionDiscount}}</li>
                    <li>{{today.returnOrderAmount}}</li>
                    <li>{{today.itemFee}}</li>
                    <li>{{today.productCost}}</li>
                    <li>{{today.otherFee}}</li>
                  </ul>
                  <ul>
                    <li>{{today.advertFeePercent}}%</li>
                    <li>{{today.fbaFeePercent}}%</li>
                    <li>{{today.referralFeePercent}}%</li>
                    <li>{{today.promotionDiscountPercent}}%</li>
                    <li>{{today.returnOrderAmountPercent}}%</li>
                    <li>{{today.itemFeePercent}}%</li>
                    <li>{{today.productCostPercent}}%</li>
                    <li>{{today.otherFeePercent}}%</li>
                  </ul>
                </div>
                <div  slot="reference" class="out">
                    <span slot="reference" style="border-bottom:1px dashed gray;display:inline-block;padding:0">￥{{today.totalFee}}</span>
                </div>
              </el-popover>
            </div>
            <div>￥{{today.advertFee}}</div>
            <div>￥{{today.grossProfit}}</div>
            <div>{{today.grossProfitRate}}%</div>
          </div>
        </div>
        <div>
          <div>
            <div>
              <el-popover
                placement="bottom"
                width="310"
                trigger="hover">
                <p class="outTitle">当期总支出明细</p>
                <div class="outList">
                  <ul>
                    <li>广告花费:</li>
                    <li>FBA费用:</li>
                    <li>亚马逊佣金:</li>
                    <li>促销费用:</li>
                    <li>退款金额+退款费用:</li>
                    <li>亚马逊其他费用:</li>
                    <li>系统设置商品成本:</li>
                    <li>系统设置其他成本:</li>
                  </ul>
                  <ul>
                    <li>{{yesterdayRes.advertFee}}</li>
                    <li>{{yesterdayRes.fbaFee}}</li>
                    <li>{{yesterdayRes.referralFee}}</li>
                    <li>{{yesterdayRes.promotionDiscount}}</li>
                    <li>{{yesterdayRes.returnOrderAmount}}</li>
                    <li>{{yesterdayRes.itemFee}}</li>
                    <li>{{yesterdayRes.productCost}}</li>
                    <li>{{yesterdayRes.otherFee}}</li>
                  </ul>
                  <ul>
                    <li>{{yesterdayRes.advertFeePercent}}%</li>
                    <li>{{yesterdayRes.fbaFeePercent}}%</li>
                    <li>{{yesterdayRes.referralFeePercent}}%</li>
                    <li>{{yesterdayRes.promotionDiscountPercent}}%</li>
                    <li>{{yesterdayRes.returnOrderAmountPercent}}%</li>
                    <li>{{yesterdayRes.itemFeePercent}}%</li>
                    <li>{{yesterdayRes.productCostPercent}}%</li>
                    <li>{{yesterdayRes.otherFeePercent}}%</li>
                  </ul>
                </div>
                <div  slot="reference" class="out">
                    <span slot="reference" style="border-bottom:1px dashed gray;display:inline-block;padding:0">￥{{yesterdayRes.totalFee}}</span>
                </div>
              </el-popover>
            </div>
            <div>￥{{yesterdayRes.advertFee}}</div>
            <div>￥{{yesterdayRes.grossProfit}}</div>
            <div>{{yesterdayRes.grossProfitRate}}%</div>
          </div>
          <div>
            <div>
              <el-popover
                placement="bottom"
                width="310"
                trigger="hover">
                <p class="outTitle">当期总支出明细</p>
                <div class="outList">
                  <ul>
                    <li>广告花费:</li>
                    <li>FBA费用:</li>
                    <li>亚马逊佣金:</li>
                    <li>促销费用:</li>
                    <li>退款金额+退款费用:</li>
                    <li>亚马逊其他费用:</li>
                    <li>系统设置商品成本:</li>
                    <li>系统设置其他成本:</li>
                  </ul>
                  <ul>
                    <li>{{lastWeekRes.advertFee}}</li>
                    <li>{{lastWeekRes.fbaFee}}</li>
                    <li>{{lastWeekRes.referralFee}}</li>
                    <li>{{lastWeekRes.promotionDiscount}}</li>
                    <li>{{lastWeekRes.returnOrderAmount}}</li>
                    <li>{{lastWeekRes.itemFee}}</li>
                    <li>{{lastWeekRes.productCost}}</li>
                    <li>{{lastWeekRes.otherFee}}</li>
                  </ul>
                  <ul>
                    <li>{{lastWeekRes.advertFeePercent}}%</li>
                    <li>{{lastWeekRes.fbaFeePercent}}%</li>
                    <li>{{lastWeekRes.referralFeePercent}}%</li>
                    <li>{{lastWeekRes.promotionDiscountPercent}}%</li>
                    <li>{{lastWeekRes.returnOrderAmountPercent}}%</li>
                    <li>{{lastWeekRes.itemFeePercent}}%</li>
                    <li>{{lastWeekRes.productCostPercent}}%</li>
                    <li>{{lastWeekRes.otherFeePercent}}%</li>
                  </ul>
                </div>
                <div  slot="reference" class="out">
                    <span slot="reference" style="border-bottom:1px dashed gray;display:inline-block;padding:0">￥{{lastWeekRes.totalFee}}</span>
                </div>
              </el-popover>
            </div>
            <div>￥{{lastWeekRes.advertFee}}</div>
            <div>￥{{lastWeekRes.grossProfit}}</div>
            <div>{{lastWeekRes.grossProfitRate}}%</div>
          </div>
        </div>
      </div>
      <div class="third" style="background:#58A55C">
        <div>
          <div>
            <div>销量
              <el-tooltip effect="dark" placement="top">
                <div slot="content" style="line-height:25px;width:300px">
                  <span>销售订单<span style="color: #CD853F">（不包括：取消、预售未付款授权 状态的订单）</span>中的商品数量的总和。</span>
                </div>
                <span class="el-icon-question"></span>
              </el-tooltip>
            </div>
            <div>订单量
              <el-tooltip effect="dark" placement="top">
                <div slot="content" style="line-height:25px;width:400px">
                  <span>销售订单<span style="color: #CD853F">（不包括：取消、预售未付款授权 状态的订单）</span>的数量。</span><br/>
                  <span>1、销售订单的归属日期, 以下单时间进行统计; </span><br/>
                  <span>2、如果订单发生退款, 此订单会正常统计订单量; </span><br/>
                </div>
                <span class="el-icon-question"></span>
              </el-tooltip>
            </div>
            <div>广告订量
              <el-tooltip effect="dark" placement="top">
                <div slot="content" style="line-height:25px;width:400px">
                  <span>通过广告带来的订单量。</span><br/>
                  <span>1、因近30天（尤其近3天）亚马逊接口返回的数据, 可能与亚马逊控制台展现的数据存在略微不一致,</span><br/>
                  <span>因此可能导致系统统计结果与亚马逊控制台展现的数据存在略微不一致;</span><br/>
                  <span>2、以商品和站点不同维度查看数据, 因数据来自亚马逊不同的接口, </span><br/>
                  <span>不同接口的请求时间或者报告生成时间可能不一致, 因此可能导致两个维度下统计结果存在略微不一致;</span>
                </div>
                <span class="el-icon-question"></span>
              </el-tooltip>
            </div>
            <div>订单占比
              <el-tooltip effect="dark" placement="top">
                <div slot="content" style="line-height:25px;width:300px">
                  <span>通过广告带来的订单量占总订单量的比例。</span><br/>
                  <span>计算规则: 广告订单量占比 = 广告订单量/订单量*100%;</span>
                </div>
                <span class="el-icon-question"></span>
              </el-tooltip>
            </div>
          </div>
          <div>
            <div>{{today.salesQuantity}}</div>
            <div>{{today.orderQuantity}}</div>
            <div>{{today.advertQuantity}}</div>
            <div>{{today.advertProportion}}%</div>
          </div>
        </div>
        <div>
          <div>
            <div>{{yesterdayRes.salesQuantity}}</div>
            <div>{{yesterdayRes.orderQuantity}}</div>
            <div>{{yesterdayRes.advertQuantity}}</div>
            <div>{{yesterdayRes.advertProportion}}%</div>
          </div>
          <div>
            <div>{{lastWeekRes.salesQuantity}}</div>
            <div>{{lastWeekRes.orderQuantity}}</div>
            <div>{{lastWeekRes.advertQuantity}}</div>
            <div>{{lastWeekRes.advertProportion}}%</div>
          </div>
        </div>
      </div>
    </div>
    <div style="width:100%;height:570px;margin-bottom:20px" v-loading='barLoading' element-loading-text="拼命加载中">
      <Cylindricity v-if="barInfo!=''" :barInfo='barInfo'>
        <div style="text-align:center">
            <el-button :type="type=='order_total'? 'primary':''" @click="change('order_total')">销售额</el-button>
            <el-button :type="type=='order_quantity'? 'primary':''" @click="change('order_quantity')">订单量</el-button>
            <el-button :type="type=='quantity_ordered'? 'primary':''" @click="change('quantity_ordered')">销&nbsp;&nbsp;量</el-button>
        </div>
      </Cylindricity>
    </div>
        <el-select v-model="pageInfo.status" clearable style="width：150px;margin-right:10px" placeholder="请选择商品状态">
            <el-option 
            :label="item.value" 
            :value="item.id"
            v-for="item in productStatusList"
            :key="item.id"></el-option>
        </el-select>
        <!-- <el-select clearable style="width：150px;margin-right:10px" placeholder="选择查看方式">
            <el-option 
            :label="item.value" 
            :value="item.id"
            v-for="item in stockStatusList"
            :key="item.id"></el-option>
        </el-select> -->
        <el-select clearable v-model="pageInfo.shipType" style="width：150px;margin-right:10px" placeholder="请选择配送方式">
            <el-option 
            :label="item.value" 
            :value="item.id"
            v-for="item in sendTypeList"
            :key="item.id"></el-option>
        </el-select>
        <el-input style="width:300px;margin-right:10px" placeholder="请输入ASIN" v-model="keyword" class="input-with-select">
            <el-select clearable @clear="clearType" style="width:130px" v-model="select" slot="prepend" placeholder="选择输入类型">
                <el-option 
                :label="item.value"
                :value="item.id"
                v-for="item in searchList"
                :key="item.id"></el-option>
            </el-select>
        </el-input>
        <el-button type="primary" icon="el-icon-search" @click="find">查询</el-button>
        <TableDialog :tableList='headerList' @setTableHead=setHead style="float:right" ></TableDialog>
        <el-table
          border
          :data='tableData'
          :cell-style="{ fontSize: '13px', padding: '7px 0',textAlign:'center' }"
          style="width: 100%;margin-top:20px"
          v-loading='tableLoading'
          @sort-change="sortTable"
          highlight-current-row
          :header-cell-style="{
              fontSize: '13px',
              padding: '9px 0',
              marginTop: '10px',
              textAlign:'center',
              background:'#EBEEF5'
          }">
            <el-table-column prop="date" width="230" fixed="left">
              <template slot="header">
                <span>商品信息</span>
                <el-tooltip effect="dark" placement="top">
                  <div slot="content">
                    <span>鼠标悬停在图片上, 可以查看大图</span><br/>
                    <span>如果切换商品的统计方式, 可以按子体或父体, SKU统计</span>
                  </div>
                  <span class="el-icon-question"></span>
                </el-tooltip>
              </template>
              <template slot-scope="scope">
                <GoodsImage
                  :marketPlaceImageUrl="scope.row.marketPlaceImageUrl"
                  :label="scope.row.label"
                  :parentASIN="scope.row.parentAsin"
                  :imageUrl="scope.row.imageUrl"
                  :asin="scope.row.asin"
                  :title="scope.row.title"
                  :commoditySku="scope.row.commoditySku"
                ></GoodsImage>
                </template>
            </el-table-column>
            <el-table-column 
            :label="item.name" 
            v-for="item in head"
            :key='item.prop'
            :prop="item.prop"
            :sortable="true"
            :min-width="item.width">
            <template slot="header">
                <span v-if="item.name=='广告销售额'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:25px;width:400px">
                          <span>通过广告带来的销售额。</span><br/>
                          <span>1、因近30天（尤其近3天）亚马逊接口返回的数据, 可能与亚马逊控制台展现的数据存在略微不一致,</span><br/>
                          <span>因此可能导致系统统计结果与亚马逊控制台展现的数据存在略微不一致;</span><br/>
                          <span>2、以商品和站点不同维度查看数据, 因数据来自亚马逊不同的接口,</span><br/>
                          <span>不同接口的请求时间或者报告生成时间可能不一致, 因此可能导致两个维度下统计结果存在略微不一致;</span>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='ASoTS'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:25px">
                          <span>通过广告所带来的销售额占总销售额的比例。</span><br/>
                          <span>计算规则：ASoTS = 广告销售额 / 销售额 * 100%;</span>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='销量'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:25px;width:300px">
                          <span>销售订单<span style="color: #CD853F">（不包括：取消、预售未付款授权 状态的订单）</span>中的商品数量的总和。</span>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='订单量'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:25px;width:400px">
                          <span>销售订单<span style="color: #CD853F">（不包括：取消、预售未付款授权 状态的订单）</span>的数量。</span><br/>
                          <span>1、销售订单的归属日期, 以下单时间进行统计; </span><br/>
                          <span>2、如果订单发生退款, 此订单会正常统计订单量; </span><br/>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='广告订单量'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:25px;width:400px">
                          <span>通过广告带来的订单量。</span><br/>
                          <span>1、因近30天（尤其近3天）亚马逊接口返回的数据, 可能与亚马逊控制台展现的数据存在略微不一致,</span><br/>
                          <span>因此可能导致系统统计结果与亚马逊控制台展现的数据存在略微不一致;</span><br/>
                          <span>2、以商品和站点不同维度查看数据, 因数据来自亚马逊不同的接口, </span><br/>
                          <span>不同接口的请求时间或者报告生成时间可能不一致, 因此可能导致两个维度下统计结果存在略微不一致;</span>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='广告点击量'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:350px">
                            <p>点击量：广告被点击的总次数。</p>
                            <p>点击率：每一次曝光被点击的概率。</p><br>
                            <p>计算规则：广告点击率 = 点击量 / 曝光量 * 100%；</p><br>
                            <p>亚马逊广告点击率一般在0.6%-2.5%左右。</p>
                            <p>点击率高：说明选词匹配度越高，同时您的商品标题、主图、价格、评论数量，较好的吸引用户点击。</p>
                            <p>点击率低：建议对选词，商品标题、主图、价格、评论数量进行优化。</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='广告点击率'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:350px">
                            <p>点击量：广告被点击的总次数。</p>
                            <p>点击率：每一次曝光被点击的概率。</p><br>
                            <p>计算规则：广告点击率 = 点击量 / 曝光量 * 100%；</p><br>
                            <p>亚马逊广告点击率一般在0.6%-2.5%左右。</p>
                            <p>点击率高：说明选词匹配度越高，同时您的商品标题、主图、价格、评论数量，较好的吸引用户点击。</p>
                            <p>点击率低：建议对选词，商品标题、主图、价格、评论数量进行优化。</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='CVR'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:300px">
                            <p>每一次点击带来订单的概率。</p><br>
                            <p>计算规则：CVR = 订单数 / 点击量 * 100%；</p>
                            <p>例如：CVR为10%，每100次点击，能带来10笔广告订单。</p><br>
                            <p>亚马逊广告，CVR一般为5-10%左右，</p>
                            <p>CVR的重要影响因素：商品图片、卖点、评论内容、促销活动，如果CVR低，建议您优化listing，根据商品的受众人群，提炼卖点、设计精美的场景图、善用Q&A，Review等。</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='销售额'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:300px">
                            <p>通过广告带来的销售额。</p><br>
                            <p>1、因近30天（尤其近3天）亚马逊接口返回的数据，可能与亚马逊控制台展现的数据存在略微不一致，因此可能导致系统统计结果与亚马逊控制台展现的数据存在略微不一致；</p>
                            <p>2、以商品和站点不同维度查看数据，因数据来自亚马逊不同的接口，不同接口的请求时间或者报告生成时间可能不一致，因此可能导致两个维度下统计结果存在略微不一致；</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='广告曝光量'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:220px">
                            <p>广告投放给买家的展示总数。</p><br>
                            <p>没有曝光量就无法带来点击及购买转化，因此整体预算内，曝光量越高越好。</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='广告花费'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:350px">
                            <p>广告被点击，所产生的总费用，相当于您的广告总成本 。</p><br>
                            <p>1、因近30天（尤其近3天）亚马逊接口返回的数据，可能与亚马逊控制台展现的数据存在略微不一致，因此可能导致系统统计结果与亚马逊控制台展现的数据存在略微不一致；</p><br>
                            <p>2、以商品和站点不同维度查看数据，因数据来自亚马逊不同的接口，不同接口的请求时间或者报告生成时间可能不一致，因此可能导致两个维度下统计结果存在略微不一致；</p>
                            <p>3、计算规则：广告花费 = CPC竞价 * 被点击次数；</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='ACoTS'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:350px">
                            <p>广告花费占总销售的比例。</p><br>
                            <p>计算规则: ACoTS = 广告花费/整体销售额*100%;</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='广告占比'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:350px">
                            <p>通过广告带来的订单量占总订单量的比例。</p><br>
                            <p>计算规则: 广告订单占比 = 广告订单量/订单量*100%;</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='退货数'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:350px">
                            <p>退款订单中的商品数量的总和。</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='退货率'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:350px">
                            <p>计算规则: 退货率 = 退货量/销售量*100%</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='访问次数'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:350px">
                          <p>用户访问您的 Amazon.com 页面的次数。24 小时内的所有活动都将视为一次会话。</p>
                          <p>数据来自于亚马逊后台业务报告, 可在 “经营分析” - “业务报告” 中进行上传。</p>
                          <p><span style="color:orange">特别说明:</span>特别说明：按SKU维度查看数据时, 如果1个ASIN下有多个SKU, 导入的ASIN业务报告数据,只会随机填充到一个SKU, 这会导致其它的SKU没有业务报告导入的相关数据。</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='访问转化率'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:350px">
                          <p>计算规则：访问转化率 = 销量 / 访问次数 * 100%。</p>
                          <p>访问次数, 数据来自于亚马逊后台业务报告, 可在 “经营分析” - “业务报告” 中进行上传。</p>
                          <p><span style="color:orange">特别说明:</span>按SKU维度查看数据时, 如果1个ASIN下有多个SKU, 导入的ASIN业务报告数据,只会随机填充到一个SKU, 这会导致其它的SKU没有业务报告导入的相关数据。</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='毛利率'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:350px">
                            <p>计算规则: 毛利润 = 销售额-总支出</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='ACoS'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:300px">
                            <p>广告销售成本率，广告花费占广告销售额的比例。</p><br>
                            <p>计算规则：ACoS = 广告花费 / 广告销售额 * 100%；</p>
                            <p>例如，ACoS为90%，100美元的销售额，需要支付90%（90元）广告费。</p>
                            <p>ACoS是衡量广告效果的重要指标，一般来说越低越好，</p>
                            <p>25%是业内普遍的衡量标准，</p>
                            <p>但并不能用ACoS指标单一来衡量广告的效果。</p><br>
                            <p>在不同的细分市场、商品周期、用户周期，品牌定位，都影响着ACoS的设置目标，</p>
                            <p>例如，客户复购率高、忠诚度高、终身价值高，或是有品牌光环和生态的商品，</p>
                            <p>通过广告一次获客，客户可带来长期的购买回报，</p>
                            <p>因此这类商品ACoS高，反而有益于创造更多利润。</p><br>
                            <p>拓展阅读<a href="https://www.baidu.com/s?ie=UTF-8&wd=ACoS%E8%B6%8A%E4%BD%8E%E8%B6%8A%E5%A5%BD%E5%90%97%EF%BC%9F&tn=62095104_41_oem_dg" style="color:#13CE66">ACoS越低越好吗？</a></p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='总支出'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:25px;width:400px">
                          <span>所有支出的总和, 支出项包括：广告花费、FBA费用、亚马逊佣金、促销费用、退款金额 + 退款费用、</span><br/>
                          <span>亚马逊其它费用、系统设置商品成本 、系统设置其它成本 。</span><br/>
                          <span><span style="color: #CD853F">特别说明</span>：1、如果亚马逊平台代收了增值税, 则亚马逊其它费用中会包含增值税, 即总支出中包括增值税; </span><br/>
                          <span>2、coupon类促销订单, 系统的促销费用中会包括亚马逊收取的费用, 即总支出中包括coupon的使用费用,</span><br/>
                          <span>因此系统订单中展示的促销费用可能大于亚马逊交易一览中的促销金额; </span><br/>
                          <span>1、如果订单发生退款, 此订单会正常统计销售额, 对应的退款金额会以退款时间, 统计到当日总支出的退款项中;</span><br/>
                          <span>2、部分pending订单, 亚马逊短时间内, 返回的销售数据可能为0, </span><br/>
                          <span>此时系统会按最近成交的情况进行预算<span style="color: #CD853F">（预算数据项包括：销售额、亚马逊佣金、FBA费用）</span>;</span><br/>
                          <span>3、亚马逊其它费用：亚马逊订单明细中, 除了 商品价格、FBA费用、佣金、促销费用 <span style="color: #CD853F">之外的其它费用总和</span>,</span><br/>
                          <span>此值出现负数的原因是：a、有些促销活动是亚马逊平台搞的, 但是这部分费用会被一并统计到总支出的促销费用中,</span><br/>
                          <span>因此需要进行对冲。b、增值税亚马逊未代收, 此时增值税需要加入到收入中, 对于费用来说是负数;</span><br/>
                          <span>c、FBM订单，运费需要加入到收入中，对于费用来说是负数;</span><br/>
                          <span>4、系统设置其它成本：店铺实际运营过程中,产生的一些特殊费用, 可以是收入, 也可以是支出, </span><br/>
                          <span>用于准确计算利润, 可在“库存管理 - 其它成本”中进行上传;</span><br/>
                          <span>5、系统设置商品成本：包括商品的采购成本、物流成本（头程 / FBM）、其它费用，可在“库存管理 - 商品成本”中进行设置或者上传;</span>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else>{{item.name}}</span>
            </template>
            <template slot-scope="scope">
              <span>
                <span v-if="item.currency">{{currency}}</span>
                {{scope.row[item.prop]}}</span>
            </template>
            </el-table-column>
        </el-table>
        <div style="text-align:center;margin-top:20px">
            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageInfo.current"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
            </el-pagination>
        </div>
  </div>
</template>

<script>
import request from '@/utils/request.js'
import Cylindricity from '@/components/Cylindricity.vue'
import ShopsAndSites from '@/components/ShopsAndSites.vue'
import TableDialog from '@/components/tableDialog.vue'
import GoodsImage from '@/components/GoodsImage'
import {getHomePageMsg,barGraph,pageInit,listPage} from '@/api/home.js'
export default {
  components:{
    Cylindricity,
    ShopsAndSites,
    TableDialog,
    GoodsImage
  },
  data(){
    return {
      tableData:[],
      tableLoading:false,
      barLoading:false,
      type:'order_total',
      shopId:'',
      marketPlaceId:'',
      field:'',
      topLoading:false,
      today:{},
      yesterdayRes:{},
      lastWeekRes:{},
      barInfo:'',

      searchList:[],
      sendTypeList:[],
      statusList:[],
      productStatusList:[],
      tress:[],
      select:'',
      keyword:'',
      pageInfo:{
        current:'',
        pageSize:'',
        status:'',
        shipType:'',
        sortColumn:'',
        sort:''
      },
      total:'',
      head:[],
      headerList:[
        {name:'销售额',prop:'salesAmount',width:120,currency:true},
        {name:'广告销售额',prop:'adSalesAmount',width:130,currency:true},
        {name:'ASoTS',prop:'asots',width:120},
        {name:'销量',prop:'salesQuantity',width:120},
        {name:'订单量',prop:'orderQuantity',width:120},
        {name:'广告订单量',prop:'advertQuantity',width:130},
        {name:'广告占比',prop:'advertProportion',width:120},
        {name:'总支出',prop:'totalFee',width:120,currency:true},
        {name:'广告花费',prop:'advertFee',width:120,currency:true},
        {name:'ACoTS',prop:'acots',width:120},
        {name:'ACoS',prop:'acos',width:120},
        {name:'退货数',prop:'returnQuantity',width:120},
        {name:'退货率',prop:'returnRate',width:120},
        {name:'毛利率',prop:'grossProfitRate',width:120},
        {name:'广告曝光量',prop:'advertExposure',width:130},
        {name:'广告点击量',prop:'advertClicks',width:130},
        {name:'广告点击率',prop:'advertClickRate',width:130},
        {name:'CVR',prop:'cvr',width:120},
        {name:'访问次数',prop:'visitsNumber',width:120},
        {name:'访问转化率',prop:'visitsRate',width:130},
      ],
      currency:'',

    };
  },
  created(){
    // this.getListPage()
    // this.HomePageMsg()
    // this.getBar()
    this.getPageInit()
    this.head=this.headerList

    // this.barInfo={b:4}
  },
  methods:{
    clear(){
      this.shopId=''
      this.marketPlaceId=''
      this.getListPage()
      this.HomePageMsg()
      this.getBar()
    },
    sortTable(column){
        if(column.order=='ascending'){
            this.pageInfo.sort='asc'
        }else if(column.order=='descending'){
            this.pageInfo.sort='desc'
        }else if(column.order==null){
            this.pageInfo.sort=''
            this.pageInfo.sortColumn=''
            this.getListPage()
            return
        }
        this.pageInfo.sortColumn=column.prop
        this.getListPage()
    },
    getID(shopID,marketPlaceId,name,currency){
        this.shopId=shopID
        this.marketPlaceId=marketPlaceId
        this.currency=currency
        // console.log(shopID,marketPlaceId,this.pageInfo);
        this.pageInfo={
          current:'',
          pageSize:'',
          status:'',
          shipType:'',
        }
        this.getListPage()
        this.getBar()
        this.HomePageMsg()
    },
    handleSizeChange(val){
      this.pageInfo.pageSize=val
      this.getListPage()
    },
    handleCurrentChange(val){
      this.pageInfo.current=val
      this.getListPage()
    },
    getListPage(){
        let sku = ''
        if(this.select==''||this.keyword==''){
            sku=null
        }else if(this.select=='asin'){
            sku={asin:this.keyword}
        }else if(this.select=='parent_asin'){
            sku={parent_asin:this.keyword}
        }else if(this.select=='commodity_sku'){
            sku={commodity_sku:this.keyword}
        }
        this.tableLoading=true 
        listPage({...sku,...this.pageInfo,shopId:this.shopId,marketPlaceId:this.marketPlaceId}).then(res=>{
            this.tableLoading=false
            this.tableData=res.data.values
            this.total=res.data.pageInfo.total
        }).catch(()=>{
            this.tableLoading=false
        })
    },
    clearType(){
        this.select=''
        this.keyword=''
    },
    change(type){
        this.type=type
        this.field=type
        // this.a=Math.random() * 600
        this.getBar()
    },
    HomePageMsg(){
      this.topLoading=true
      getHomePageMsg({shopId:this.shopId,marketPlaceId:this.marketPlaceId}).then(res=>{
        this.topLoading=false
        if(res.data.code==200){
          this.today=res.data.data.today
          this.lastWeekRes=res.data.data.lastWeekRes
          this.yesterdayRes=res.data.data.yesterdayRes
        }else{
          this.$message.warning(res.data.message)
        }
      }).catch(error=>{
        this.topLoading=false
      })
    },
    getBar(){
      this.barInfo=''
      this.barLoading=true
      barGraph({shopId:this.shopId,marketPlaceId:this.marketPlaceId,field:this.field}).then(res=>{
        this.barLoading=false
        this.barInfo=res.data.data
      })
    },
    getPageInit(){
      this.barLoading=true
      this.topLoading=true
      this.tableLoading=true
      pageInit().then(res=>{
          this.tress=res.data.data.tress
          this.searchList=res.data.data.searchList
          this.statusList=res.data.data.statusList
          this.sendTypeList=res.data.data.sendTypeList
          this.productStatusList=res.data.data.productStatusList
          console.log(this.shopId,this.marketPlaceId);
      })
    },
    find(){
      this.pageInfo.current=1
      this.getListPage()
    },
    setHead(val){
      this.head=val
      console.log(val);
      console.log(this.head);
    }
  }
}
</script>

<style scoped lang="scss">
.TOP{
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  color: white;
}
.TOP>div{
  padding: 10px 15px;
  box-sizing: border-box;
  width: 32%;
  height: 200px;
  display: flex;
  flex-direction: column;
  border-radius: 9px;
  >div:nth-of-type(1){
    border-bottom: 1px dashed rgba(255,255,255,.4);
  }
  >div{
    height: 110px;
    // background: red;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    >div{
      display: flex;
      justify-content: space-between;
      >div{
        width: 125px;
        height: 35px;
        text-align: center;
        line-height: 35px;
      }
    }
  }
}
.first,.third{
  // background: #7c75f3;
  // background-image: linear-gradient(to bottom right, #409EFF 70%, #72B8FF);
  background: #409EFF;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.5);
}
.second{
  margin: 0 20px;
  background: #34bfa3;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.5);
}
// .noPic{
//     width: 60px;
//     height: 60px;
//     text-align: center;
//     line-height: 60px;
//     font-size:8px;
//     color: #909399;
//     margin-right: 15px;
//     span{
//         display: block;
//         background: #EBEEF5;
//         width: 100%;
//         height: 100%;
//     }
// }
// .infoText{
//     overflow: hidden;
//     display: -webkit-box;
//     -webkit-line-clamp: 2;
//     -webkit-box-orient: vertical;
//     line-height: 25px;
//     margin: 15px 0 20px 0;
// }
// .goodsInfo{
//     display: flex;
//     >div:nth-of-type(2){
//         display: flex;
//         flex-direction: column;
//         justify-content: space-evenly;
//     }
//     p{
//         font-size: 12px;
//         color: #909399;
//     }
// }
// .pic{
//     width:160px;
//     height: 160px;
//     font-size:8px;
//     color: #909399;
//     text-align: center;
//     line-height: 160px;
//     margin-right: 10px;
//     span{
//         display: block;
//         background: #EBEEF5;
//         width: 100%;
//         height: 100%;
//     }
// }
// .suspend{
//     p{
//         span{
//             display: inline-block;
//             width: 180px;
//             margin-right: 10px;
//             font-size: 13px;
//         }
//     }
// }
// img{
//     vertical-align: middle;
// }
.out{
  display: inline-block;
  padding-bottom: 2px;
}
.out:hover{
  font-weight: bold;
  cursor: pointer;
}
.outTitle{
  border-bottom: 1px solid #F0F0F0;
  padding: 2px 3px 10px;
}
.outList{
  display: flex;
  ul{
    line-height: 30px;
    text-align: right;
  }
  ul:nth-of-type(1){
    margin-right: 10px;
    width: 130px;
  }
  ul:nth-of-type(3){
    margin-left: 30px;
    font-size: 12px;
    color: #999999;
    width: 50px;
    text-align: right;
  }
  ul:nth-of-type(2){
    width: 75px;
    font-size: 12px;
    color: #999999;
  }
}
</style>